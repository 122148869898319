import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Cantilevered Concrete Balcony' },
    { img: data.i2, caption: 'Cantilevered Concrete Balcony' },
    { img: data.i3, caption: 'Cantilevered Concrete Balcony' },
    { img: data.i4, caption: 'Cantilevered Concrete Balcony' },
    { img: data.i5, caption: 'Cantilevered Concrete Balcony' },
    { img: data.i6, caption: 'Cantilevered Concrete Balcony' },
    { img: data.i7, caption: 'Cantilevered Concrete Balcony' },
    { img: data.i8, caption: 'Cantilevered Concrete Balcony' },
    { img: data.i9, caption: 'Cantilevered Concrete Balcony' },
    { img: data.i10, caption: 'Cantilevered Concrete Balcony' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: Cantilevered Epicore MSR Slabs" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>Cantilevered Epicore MSR Slabs</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>Cantilevered balconies are relatively simple with the Epicore Composite
            Floor System.  The length of the cantilever is based upon slab thickness and
            loads, but typical five to six foot cantilevered balconies are usually easily
            accommodated.  Our Infinity System Erectors pre-fabricate the balcony forms
            with the edge forming, drip-lip form, and OSHA railings already built in.
            Then they fly the forms up from floor-to-floor for maximum speed and economy.
            The end result is a nice clean cantilevered “all concrete” balcony.</p>
            <p className='type--lg'>
              <Link className="link-next" to="system-overview/ceiling-treatments/">
                Next Page: Ceiling Treatments
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="system-overview/steel-beams/">
                Prev Page: Structural Steel Beams / Lintels
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/cantilevers/gallery/01-Belde010_(33896).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i2: file(relativePath: { eq: "system-overview/cantilevers/gallery/02-Balco080_(41315).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i3: file(relativePath: { eq: "system-overview/cantilevers/gallery/03-Balco010_(42851).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i4: file(relativePath: { eq: "system-overview/cantilevers/gallery/04-RitzL020_(33923).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i5: file(relativePath: { eq: "system-overview/cantilevers/gallery/05-FourG010_(41265).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i6: file(relativePath: { eq: "system-overview/cantilevers/gallery/06-FourG030_(41267).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i7: file(relativePath: { eq: "system-overview/cantilevers/gallery/07-GABLE040_(41305).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i8: file(relativePath: { eq: "system-overview/cantilevers/gallery/08-GABLE170_(41979).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i9: file(relativePath: { eq: "system-overview/cantilevers/gallery/09-Balco090_(42969).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i10: file(relativePath: { eq: "system-overview/cantilevers/gallery/10-BroSq090_(43285).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
